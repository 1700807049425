
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Advantages from 'components/landing/Advantages';
import Cta from 'components/landing/Cta';
import Faq from 'components/landing/Faq';
import { PrimaryFeatures } from 'components/landing/PrimaryFeatures';
import Header from 'components/landing/Header';
import Hero from 'components/landing/Hero';
import { PromoCodeContext } from 'components/landing/lib/promoCodeContext';
import Pricing from 'components/landing/Pricing';
import Testimonials from 'components/landing/Testimonials';
import UseCases from 'components/landing/UseCases';
import { HostType } from 'configs/theme';
import { FC } from 'lib/component-utils';
import { useUserContext } from 'lib/context';
import { withUserProps } from 'lib/contextCreation';
import { PromoCode } from 'lib/dto';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import { setCookie } from 'nookies';
import { useEffect, useState } from 'react';
import YandexIntegration from 'components/landing/YandexIntegration';
import SellematicsHero from 'components/landing/SellematicsHero';
import AvailableMalls from 'components/landing/AvailableMalls';
import WeWorkWith from 'components/landing/WeWorkWith';
import Stats from 'components/landing/Stats';
import SellmonitorPlug from 'components/landing/SellmonitorPlug';
import Services from 'components/landing/Services';
import DemoContactForm from 'components/landing/DemoContactForm';
import ForDistributors from 'components/landing/ForDistributors';
import ForBrands from 'components/landing/ForBrands';
import SellematicsTestimonials from 'components/landing/SellematicsTestimonials';
import { useIntl } from 'lib/intl-utils';
import Features from 'components/landing/Features';
import cookieParser from 'cookie';
import AdIndexFirstPlace from 'components/landing/AdIndexFirstPlace';
import { simpleHash } from 'lib/hash';
import UzumContactForm from 'components/landing/UzumContactForm';
import Banner from 'components/landing/Banner';
const metaByHost: Partial<Record<HostType, Partial<Record<string, {
    title: string;
    description: string;
}>>>> = {
    sellmonitor: {
        ru: {
            title: 'Сервис аналитики маркетплейсов Sellmonitor для анализа продаж и трендов',
            description: '🚀 Повысьте свои продажи с нашим сервисом аналитики маркетплейсов! Отслеживание цен конкурентов, анализ трендов и товаров — все для успеха вашего бизнеса. Заходите и начинайте расти уже сегодня!',
        },
    },
    uzum: {
        uz: {
            title: 'Sellmonitor marketpleys tahlili xizmati savdo va trendlarni tahlil qilish uchun',
            description: '🚀 Bizning marketpleys tahlili xizmatimiz bilan savdolaringizni oshiring! Raqiblarning narxlarini kuzatish, trendlar va mahsulotlarni tahlil qilish — bularning barchasi biznesingiz muvaffaqiyati uchun. Kiring va bugundan o‘sishni boshlang!',
        },
        ru: {
            title: 'Сервис аналитики маркетплейсов Sellmonitor для анализа продаж и трендов',
            description: '🚀 Повысьте свои продажи с нашим сервисом аналитики маркетплейсов! Отслеживание цен конкурентов, анализ трендов и товаров — все для успеха вашего бизнеса. Заходите и начинайте расти уже сегодня!',
        },
    },
    sellscreen: {
        en: {
            title: 'Sellscreen marketplace analytics service for sales and trends analysis',
            description: '🚀 Boost your sales with our marketplace analytics service! Track competitor prices, analyze trends and products — everything for your business success. Sign up and start growing today!',
        },
        zh: {
            title: 'Sellscreen市场分析服务，用于销售和趋势分析',
            description: '🚀 通过我们的市场分析服务提升您的销售业绩！追踪竞争对手价格、分析趋势和产品 — 为您的商业成功提供一切所需。立即访问我们，今天就开始成长！',
        },
    },
    sellematics: {
        ru: {
            title: 'Sellematics — e-commerce аналитика и исследования',
            description: 'Сервис аналитики 20+ маркетплейсов. Покажем всё, что нужно вашему бизнесу',
        },
        en: {
            title: 'Sellematics — e-commerce analytics and research',
            description: 'Analytics service for 20+ e-commerce retailers. Gives you all the data your business needs',
        },
    },
};
const getServerSideProps: GetServerSideProps = withUserProps(async (context) => {
    return {
        props: {
            isResponsive: true,
        },
    };
}, { forceUnauthorizedMode: true });
const LandingPage: FC = () => {
    const ctx = useUserContext();
    const { lang } = useIntl();
    const [promoCode, setPromoCode] = useState<PromoCode>();
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('promoCode');
        if (code !== null) {
            setCookie(null, 'promoCode', code, {
                maxAge: 7 * 24 * 60 * 60,
                path: '/',
            });
            ctx.getPromoCodeSignup(code).then((data) => {
                if (!Array.isArray(data))
                    setPromoCode(data);
            });
        }
        const utmParams = [
            'utm_source',
            'utm_medium',
            'utm_campaign',
            'utm_term',
            'utm_content',
            'actionpay',
        ];
        utmParams.forEach((param) => {
            const value = params.get(param);
            if (value !== null) {
                setCookie(null, param, value, {
                    maxAge: 30 * 24 * 60 * 60,
                    path: '/',
                });
            }
        });
        const partnerParams = ['refLinkId', 'partnerId', 'prmUserId'];
        const cookies = cookieParser.parse(document.cookie);
        const hasPartnerParams = partnerParams.every((param) => cookies[param]);
        if (hasPartnerParams)
            return;
        const prmUserId = simpleHash(ctx.ip + ctx.userAgent);
        partnerParams.forEach((param) => {
            const value = param === 'prmUserId' ? prmUserId : params.get(param);
            if (value !== null) {
                setCookie(null, param, value, {
                    maxAge: 30 * 24 * 60 * 60,
                    path: '/',
                });
            }
        });
        ctx.trackPrmEvent();
    }, []);
    let meta = metaByHost[ctx.theme.hostType]![lang]!;
    return (<PromoCodeContext.Provider value={promoCode ?? null}>
      <article className="">
        <Head>
          <title>{meta.title}</title>
          <meta name="description" content={meta.description}/>
        </Head>
        <div className="min-h-[calc(min(100vh,768px))]">
          <Banner />
          <Header />
          {ctx.theme.hostType !== 'sellematics' ? <Hero /> : <SellematicsHero />}
        </div>
        {ctx.theme.hostType === 'sellematics' && <AvailableMalls />}
        {ctx.theme.hostType === 'sellematics' && <Services />}
        {ctx.theme.hostType === 'sellematics' && <ForBrands />}
        {ctx.theme.hostType === 'sellematics' && <ForDistributors />}
        {ctx.theme.hostType !== 'sellematics' && <UseCases />}
        {ctx.theme.hostType !== 'sellematics' && <PrimaryFeatures />}
        {ctx.theme.hostType === 'sellmonitor' && <Features />}
        {ctx.theme.hostType === 'sellmonitor' && <Advantages />}
        {ctx.theme.hostType === 'sellmonitor' && <AdIndexFirstPlace />}
        {ctx.theme.hostType !== 'sellematics' && <Pricing />}
        {ctx.theme.hostType === 'sellmonitor' && <YandexIntegration />}
        {ctx.theme.hostType === 'sellematics' && <Stats />}
        {ctx.theme.hostType === 'sellematics' && <SellmonitorPlug />}
        {ctx.theme.hostType === 'sellematics' && <WeWorkWith />}
        {ctx.theme.hostType !== 'sellematics' && <Testimonials />}
        {ctx.theme.hostType === 'sellematics' && <SellematicsTestimonials />}
        {ctx.theme.hostType !== 'sellematics' && <Faq />}
        {!['sellematics'].includes(ctx.theme.hostType) && <Cta />}
        {ctx.theme.hostType === 'sellematics' && <DemoContactForm />}
      </article>
    </PromoCodeContext.Provider>);
};
export default LandingPage;

    async function __Next_Translate__getServerSideProps__19155536cb5__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__19155536cb5__ as getServerSideProps }
  